import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const agreeApi = {
  getList: (isProd?'':'/api')+'/newAdmin.php?c=Agreement&a=getUserAgreement',
  getDetail: (isProd?'':'/api')+'/newAdmin.php?c=Agreement&a=getAgreementInfo',
  eidtInfo: (isProd?'':'/api')+'/newAdmin.php?c=Agreement&a=editAgreementInfo',
}


export function getList (parameter) {
  return request({
    url: agreeApi.getList,
    method: 'post',
    data: parameter
  })
}

export function getDetail (parameter) {
  return request({
    url: agreeApi.getDetail,
    method: 'post',
    data: parameter
  })
}



export function eidtInfo (parameter) {
  return request({
    url: agreeApi.eidtInfo,
    method: 'post',
    data: parameter
  })
}
