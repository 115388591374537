<template>
    <page-header-wrapper>
        <a-card :bordered="false">
            <a-table :columns="columns" :data-source="data" :rowKey="(record) => record.id" :pagination="false" @change="getChange">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="action" slot-scope="text, record">
                  <a @click="edit(record)" v-action:edit>编辑</a> 
                </span>
            </a-table>
			
			<div style="text-align: right;margin-top:20px;">
				<a-pagination v-model="pageIndex" :total="total" :show-total="total => `共 ${total} 条`" show-less-items @change="getChange" :default-current="1" /> 
			</div> 
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
	import { getUrlKey } from '@/utils'
	import merge from 'webpack-merge'
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getList,
        getDetail,
        eidtInfo
    } from '@/api/agreement'
    const columns = [{
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'90px'
        },
        {
            title: '协议名称',
            dataIndex: 'title',
            align:'left'
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]


    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                data:[],  
				pageIndex:1,
				total:0,
            }
        },
        created(){
			this.pageIndex = Number(getUrlKey('p'))||1
			this.getList(this.pageIndex)
        },
        methods: {
			getChange(e){
				console.log('e',e)
				this.$router.push({
				    query:merge(this.$route.query,{'p':e})
				})
				this.getList(e)
			},
            getList(pageIndex){
				let params = {
					pageIndex:pageIndex,
					pageSize:10
				}
                getList(params).then(res =>{
                    this.data = res.data.list
					this.total = res.data.total
                })
            },
            edit(data){
				this.$router.push({
				    query:merge(this.$route.query,{'p':this.pageIndex})
				})
                this.$router.push({path:'/operateManage/editAgree?id='+data.id+'&p='+this.pageIndex})
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            }
        }
    }
</script>
